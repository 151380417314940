import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/eventsmaster', title: 'Event Master', icon: 'unarchive', class: '' },
    { path: '/rangemaster', title: 'Range Master', icon: 'unarchive', class: '' },
    { path: '/representation', title: 'Representing', icon: 'unarchive', class: '' },
    { path: '/participant', title: 'Participants', icon: 'unarchive', class: '' },
    { path: '/tournamentMst', title: 'Tournament Master', icon: 'unarchive', class: '' },
    { path: '/tournamentGameMst', title: 'Tournament Game Master', icon: 'unarchive', class: '' },
    
    // { path: '/test', title: 'Test', icon: 'unarchive', class: '' }, 
    // { path: '/test2', title: 'Rank', icon: 'unarchive', class: '' }, 
    {path: '/tournamentPart', title: 'Tournament Participants', icon:'unarchive', class: ''},
    { path: '/toureventDetails', title: 'Tournament Details', icon: 'unarchive', class: '' },
    { path: '/toureventDetWiseScore', title: 'Details Wise Score', icon: 'unarchive', class: '' },
    { path: '/searchCompititorCard', title: 'Print Compititor Card', icon: 'unarchive', class: '' },
    { path: '/searchLaneDetails', title: 'Search Lane Details', icon: 'unarchive', class: '' },
    { path: '/searchCertificate', title: 'Certificate', icon: 'unarchive', class: '' },
    
    /* { path: '/payroll:id', title: 'Payroll', icon: 'unarchive', class: '' },
     
     { path: '/accreport', title: 'Account Report', icon: 'unarchive', class: '' },
     { path: '/salesReport', title: 'Sales Report', icon: 'unarchive', class: '' },
     { path: '/salesReportDW', title: 'Sales Report Date Wise', icon: 'unarchive', class: '' },
     { path: '/expenseReport', title: 'Participant Report', icon: 'unarchive', class: '' },
     { path: '/tournamentMst', title: 'Payroll Report', icon: 'unarchive', class: '' },
       { path: '/dailytransadmin', title: 'DT Admin', icon: 'unarchive', class: '' },
     { path: '/viewallfilerec', title: 'Admin view Files', icon: 'unarchive', class: '' }, */

];


//


export var filterOne: RouteInfo[] = [
    { path: '/participant', title: 'Participants', icon: 'unarchive', class: '' },
    {path: '/tournamentPart', title: 'Tournament Participants', icon:'unarchive', class: ''}

];

//

export var filterTwo: RouteInfo[] = [

    { path: '/accreport', title: 'Account Report', icon: 'unarchive', class: '' },
    { path: '/salesReport', title: 'Sales Report', icon: 'unarchive', class: '' },
    { path: '/salesReportDW', title: 'Sales Report Date Wise', icon: 'unarchive', class: '' },
    { path: '/expenseReport', title: 'Participant Report', icon: 'unarchive', class: '' },
    { path: '/tournamentMst', title: 'Payroll Report', icon: 'unarchive', class: '' },
    { path: '/viewallfilerec', title: 'Admin view Files', icon: 'unarchive', class: '' },


];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];

    constructor() { }

    ngOnInit() {
        // for all access  :  this.menuItems = ROUTES.filter(menuItem => menuItem);

        //console.log(JSON.parse(localStorage.getItem('deptID')),'sidebar')
        if (JSON.parse(localStorage.getItem('rep_id')) == 0) {

            this.menuItems = ROUTES.filter(menuItem => menuItem);

        } else {

            if (JSON.parse(localStorage.getItem('rep_id')) >0) {
                //this.menuItems = ROUTES.filter(menuItem => menuItem);
                this.menuItems = filterOne.filter(menuItem => menuItem);
                //console.log(this.deptid)
            } else {
                this.menuItems = ROUTES.filter(menuItem => menuItem);
                //this.menuItems = filterTwo.filter(menuItem => menuItem);
                //console.log(this.deptid)
            }
        }
        //console.log(this.menuItems)

    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
}

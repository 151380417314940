import { Component, OnInit } from '@angular/core';
import { MainService } from '../../main.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test : Date = new Date();
  apiurl:string;
  footerleft:string;
  devUrl:string
  constructor( private mainservice:MainService) { 
  	this.apiurl = mainservice.api_url;
  	this.footerleft = mainservice.footer_left;
    this.devUrl = mainservice.dev_url;
  }

  ngOnInit() {
 
  	//console.log(this.apiurl, " url from service")
  }

}

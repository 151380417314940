import { BehaviorSubject } from 'rxjs';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, LOCALE_ID } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MainService } from '../main.service';
@Component({
  selector: 'app-events-master',
  templateUrl: './events-master.component.html',
  styleUrls: ['./events-master.component.css']
})
export class EventsMasterComponent implements OnInit {

     apiurl: string;
    today: Date;
    dataSource: any[] | null;
    employee: string;
    phone: string;
    userID: string;
    id: number;
    tDate: string;
    tdate1: string;
    tTimeZone: string
    event_id: number
    event_name: string
    email: string
    designation: string
    showAdd: boolean = true;
    noofSold: number;
    cash: number;
    cc: number;
    db: number;
    total: number;
    date: Date;
    oldTotal: number;
    displayedColumns = ['Id', 'Event'];
    dailyTransForm: FormGroup;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    constructor(
        private _httpClient: HttpClient,
        private _formBuilder: FormBuilder,
        private datePipe: DatePipe,
        // private _matSnackBar: MatSnackBar,
        private router: Router,
        private MainService: MainService
    ) {
        this.apiurl = MainService.api_url;
    }

    ngOnInit(): void {



        this.employee = JSON.parse(localStorage.getItem('employees'))
        this.phone = JSON.parse(localStorage.getItem('phone'))
        this.userID = JSON.parse(localStorage.getItem('userID'))
        this.email = JSON.parse(localStorage.getItem('email'))
        this.designation = JSON.parse(localStorage.getItem('designation'))
        this.today = JSON.parse(localStorage.getItem('tdate'))

        //+ this.tTimeZone.replace('GMT', '')


        //  console.log(this.datePipe.transform(this.today, 'yyyy-MM-dd zzzz'))
        //this.tUSDate = this.datePipe.transform(this.today, 'MM-dd-yyyy');
        // console.log(this.tDate)
        // console.log(this.propertyID)
        this._httpClient.get<any[]>(`${this.apiurl}/web/events/listEvents`)//+ this.tDate
            .subscribe((response: any) => {
                this.dataSource = response;
                console.log(response);
                this.dailyTransForm = this.createProductForm();
                if (this.dataSource.length == 0) {
                    //this.showAdd = true
                    this.event_name = ''
                    this.cash = 0
                    this.cc = 0
                    this.db = 0
                    this.total = 0
                    this.id = undefined
                } else {
                    //this.showAdd = false
                    this.noofSold = this.dataSource[0].NO_SOLD
                    this.cash = this.dataSource[0].CASH
                    this.cc = this.dataSource[0].CC
                    this.db = this.dataSource[0].DB
                    this.total = this.dataSource[0].TOTAL
                    this.id = this.dataSource[0].ID
                    this.oldTotal = this.dataSource[0].TOTAL
                }

            })


        this.dailyTransForm = this.createProductForm();




    }


    editData(): any {
        return new Promise((resolve, reject) => {

            var data = {
                event_id: this.event_id,
                event_name: this.event_name,
            }
            this._httpClient.post<any[]>(`${this.apiurl}/web/event/edit`, data, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            })
                .subscribe((response: any) => {
                    console.log(response);
                    resolve(response);
                    this.MainService.ticker('Updated Successfully');
                    // Change the location with new one
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.onSameUrlNavigation = "reload";
                    const self = "./eventsmaster";
                    this.router.navigate([self]);
                }, reject);
        });
    }
    addData(): any {
        return new Promise((resolve, reject) => {


            var data = {
                event_name: this.event_name,
            }


            // console.log(str1)
            this._httpClient.post<any[]>(`${this.apiurl}/web/event/add`, data, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            })
                .subscribe((response: any) => {

                    resolve(response);
                    console.log(response)
                    // Change the location with new one
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.onSameUrlNavigation = "reload";
                    const self = "./eventsmaster";
                    this.router.navigate([self]);
                });
        });
    }
    createProductForm(): FormGroup {
        return this._formBuilder.group({
            EVENT_NAME: [this.event_name],
        });
    }

    addtoTotal(): void {

        var cash: number = this.cash
        var cc: number = this.cc
        var db: number = this.db
        this.total = cash + cc + db
        // console.log.log(this.total)
    }
    onSubmit(): void {
        alert();
    }
    getDimensionsByFilter(id) {
        return this.dataSource.filter(x => x.event_id === id);
    }
    fetchData(id: any) {
        console.log(id)
        //console.log(this.dataSource)
        var test = this.getDimensionsByFilter(id);
        this.event_name = test[0]['event_name']
        this.event_id = id
        this.showAdd = false
        //console.log(this.event_name)
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { FormControl } from '@angular/forms';
import { environment } from '../environments/environment';

declare var $: any;

export interface ticker {
  from: string,
  align: string
}

@Injectable({
  providedIn: 'root'
})

export class MainService {

  root_url: string = environment.root_url;
 // root_url1: string = environment.root_url1;
  //root_url:string = 'http://localhost:8080';
  api_url: string = this.root_url;
 // api_url1:string = this.root_url1;
  footer_left: string = 'Developed by Aaryainfoline';
  dev_url:string = "https://www.aaryainfoline.com"
  constructor(
    private ajax: HttpClient,
    private router: Router,
    private datePipe: DatePipe,
  ) {

  }

  checklogin(forward: Boolean = false) {
    this.ajax.get<any[]>(`${this.root_url}/checklogin`)

      .subscribe((response: any) => {
        //console.log(response);
        if (forward && response.loggedin) {
          if (response.user[0].DEPT_ID == 2) {
            this.router.navigate(['/representation'])
          } else {
            this.router.navigate(['/accreport'])
          }
        } else {
          if (response.loggedin) {

          } else {
           // this.router.navigate(['/login'])
           this.router.navigate(['/menuScreen'])
          }
        }


      });
  }

  timeNtoZ(time) {

    var tTimeZone;


    var tDate = this.datePipe.transform(time, 'yyyy-MM-dd');
    tTimeZone = this.datePipe.transform(time, 'zzzz');
    return tDate + ' 00:00:00.0000000 ' + tTimeZone.replace('GMT', '')

  }
  timeZtoN(date) {


    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');


  }

  ticker(message: string = "message", color = 'success',
    data: ticker = {
      from: 'top',
      align: 'right'
    }) {
    //const type = ['','info','success','warning','danger'];

    //const color = Math.floor((Math.random() * 4) + 1);

    $.notify({
      icon: "notifications",
      message: message

    }, {
      type: color,
      timer: 2000,
      placement: {
        from: data.from,
        align: data.align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }


}
